import { GetSsrABTestParams } from './getSsrABTest';

export const HERO_COPIES_SSR_AB: GetSsrABTestParams = {
  fallback: 'copies-set-4',
  key: 'ab_hero_copies_july2023',
  version: 2,
  tests: [
    {
      percentage: 1,
      value: 'copies-set-4',
    },
  ],
};

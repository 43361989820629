import type Contentful from 'contentful';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import theme from '../../styles/theme';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  transition: height 0.3s ease;
`;

const Image = styled.img`
  height: 1.4rem;
  object-fit: contain;
`;

const Message = styled.span`
  margin: 0 1rem;
`;

export interface PartnerType {
  appUrl?: Contentful.EntryFields.Text;
  bannerStyle: Contentful.EntryFields.Object;
  bannerText: Contentful.EntryFields.Text;
  coupon: Contentful.EntryFields.Text;
  heroCtaText: Contentful.EntryFields.Text;
  heroDescriptionText: Contentful.EntryFields.Text;
  heroFooterText: Contentful.EntryFields.Text;
  heroHeaderText: Contentful.EntryFields.Text;
  heroImage?: Contentful.Asset;
  heroStyle: Contentful.EntryFields.Object;
  name: Contentful.EntryFields.Text;
  partnerLogo?: Contentful.Asset;
}

interface PartnerBannerProps {
  partner: Pick<PartnerType, 'bannerStyle' | 'partnerLogo' | 'bannerText'>;
  onClick?: () => void;
}

export const PartnerBanner: React.FC<PartnerBannerProps> = ({ onClick, partner }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const color = partner?.bannerStyle?.fontColor ?? '#FFFFFF';
  const backgroundColor = partner?.bannerStyle?.backgroundColor ?? theme.colors.brandPrimary;

  return (
    <Container
      style={{
        backgroundColor,
        cursor: onClick ? 'pointer' : undefined,
        height: isMounted ? '3rem' : '0',
      }}
      onClick={onClick}
    >
      {partner.partnerLogo && (
        <Image
          alt={partner.partnerLogo.fields.description}
          src={partner.partnerLogo.fields.file.url}
        />
      )}
      <Message style={{ color }}>{partner.bannerText}</Message>
    </Container>
  );
};

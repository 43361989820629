import styled from 'styled-components';

import { mediaQuery } from '../../styles/global';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  color: #ecf0f1;
  word-wrap: normal;
`;

export const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  justify-content: space-between;
  margin: 2rem 0;
  padding: 0 1rem;
  gap: 0.5rem;

  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }
`;

export const TabletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;

  ${mediaQuery.maxWidth.phone} {
    flex-direction: column;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 205px;
  padding: 1rem;

  ${mediaQuery.maxWidth.tablet} {
    flex-grow: 1;
  }

  ${mediaQuery.maxWidth.phone} {
    padding-bottom: 3rem;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    // flex-shrink: 0;
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;

  border: 1px solid red;

  ${mediaQuery.maxWidth.tablet} {
    max-width: 500px;
    padding-left: 10%;
    flex-grow: 1;
  }

  ${mediaQuery.maxWidth.custom(340)} {
    flex-direction: column;
    text-align: center;
    padding-left: 0;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FirstColumn = styled(Column)`
  justify-content: space-between;
  gap: 24px;
`;

export const Row = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

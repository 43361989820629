import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { AnalyticsEvent } from '../../utils/analytics';

const Button = styled.button<{ isLoading?: boolean }>`
  cursor: ${props => (props.isLoading ? 'wait' : 'pointer')};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  text-decoration: none;
  text-align: center;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  margin: 0 0.25rem;
`;

export interface ButtonProps {
  analyticsEvent: AnalyticsEvent;
  href?: string;
  isLoading?: boolean;
  isFullWidth?: boolean;
  type?: string;
  testId?: string;
  onClick?(): void;
  children?: ReactNode;
  style?: CSSProperties;
}

export default Button;

import * as Sentry from '@sentry/nextjs';
import { Formik } from 'formik';
import { CredentialResponse } from 'google-one-tap';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import * as Yup from 'yup';

import { API_BASE_URL, WEB_APP_SIGNIN_URL } from '../../globals';
import { useInitializeGoogleAuth } from '../../hooks/useInitializeGoogleAuth';
import { fetcher, setToken } from '../../utils';
import { trackSignUpAttempt, trackSignUpError, trackSignUpSuccess } from '../../utils/analytics';
import { Assets } from '../../utils/assets';
import { validateAndTrackSignUp } from '../../utils/validateAndTrackSignUp';
import { BaseButton, GhostButton } from '../button';
import { AppleButton } from './AppleButton';
import { Disclaimer } from './Disclaimer';
import { FacebookButton } from './FacebookButton';
import { Input } from './Input';
import { OtherOptions } from './OtherOptions';

const FadeInAnimation = keyframes`
  0% {
    opacity: 0
  }
  10% {
    opacity: 1
  }
  90% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
`;

const HeaderTitle = styled.h1`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.extraLarge};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  margin: -7rem 0.5rem 1rem;
`;

const Form = styled.form`
  margin: 1rem 0;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 1rem;
  width: 465px;
  max-width: calc(100vw - 20px);
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.3rem;
`;

const SignUpWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;

const ErrorMessage = styled.div`
  min-height: 40px;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  background-color: red;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  animation: ${FadeInAnimation} 3s linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const BottomContent = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

const validationSchema = Yup.object({
  name: Yup.string().min(1),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().min(6).required('Password is required'),
});

interface SignupFormType {
  isModal?: boolean;
  onClose?: undefined;
}
interface SignupFormModalType {
  onClose: () => void;
  isModal: true;
}

type SignupFormProps = SignupFormType | SignupFormModalType;

export const SignupForm: React.FC<SignupFormProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const searchParams = useSearchParams();
  const utmSource = searchParams.get('utm_source') || '';

  useEffect(() => {
    if (errorMessage) {
      const timeoutId = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [errorMessage]);

  const handleOnSubmit = async values => {
    try {
      setIsLoading(true);
      await trackSignUpAttempt('email');
      const response = await fetcher(`${API_BASE_URL}/auth/email-signup`, {
        method: 'POST',
        body: JSON.stringify({ ...values, utmSource: utmSource || undefined }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        try {
          await validateAndTrackSignUp({ token: response.result, event: 'sign_up_email' });
          await trackSignUpSuccess('email');
        } catch (err) {
          Sentry.captureException('[CRITICAL]: something went wrong with email sign up.', {
            extra: { reason: err },
          });
          await trackSignUpError('email', err);
        } finally {
          setToken(response.result, { isOnboarding: true });
        }
      } else {
        setIsLoading(false);
        if (response.messages && response.messages.length) {
          setErrorMessage(response.messages[0]);
        }

        await trackSignUpError('email', response.messages?.[0] || 'unknown');
      }
    } catch (err) {
      Sentry.captureException('[CRITICAL]: Something went wrong with email sign up.', {
        extra: { reason: err },
      });
    }
  };

  const handleFacebookSignUp = async ({ authResponse }) => {
    try {
      await trackSignUpAttempt('facebook');
      const { accessToken, userID } = authResponse;

      const response = await fetcher(`${API_BASE_URL}/auth/facebook-signup`, {
        method: 'POST',
        body: JSON.stringify({
          userID,
          accessToken,
          utmSource: utmSource || undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        await validateAndTrackSignUp({ token: response.result, event: 'sign_up_facebook' });
        await trackSignUpSuccess('facebook');
        setToken(response.result, { isOnboarding: true });
      } else {
        await trackSignUpError('facebook', response?.messages?.[0]);
        if (response.messages && response.messages.length) {
          setErrorMessage(response.messages[0]);
        }
      }
    } catch (err) {
      await trackSignUpError('facebook', err);
      Sentry.captureException('handleFacebookSignUp(): SignUp error', { extra: { reason: err } });
    }
  };

  const handleAppleSignUp = async () => {
    try {
      trackSignUpAttempt('apple');

      const { authorization } = await AppleID.auth.signIn();

      const response = await fetcher(`${API_BASE_URL}/auth/apple-signup`, {
        method: 'POST',
        body: JSON.stringify({
          accessToken: authorization.id_token,
          utmSource: utmSource || undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        await validateAndTrackSignUp({ token: response.result, event: 'sign_up_apple' });
        await trackSignUpSuccess('apple');
        setToken(response.result, { isOnboarding: true });
      } else {
        await trackSignUpError('apple', response?.messages?.[0]);
        if (response.messages && response.messages.length) {
          setErrorMessage(response.messages[0]);
        }
      }
    } catch (error) {
      console.log('Apple Sign Up error', error);

      await trackSignUpError('apple', error);
      if (error.error !== 'popup_closed_by_user') {
        Sentry.captureException('handleAppleSignUp(): SignUp error', { extra: { reason: error } });
      }
    }
  };
  const trackGoogleSignUp = useCallback(() => {
    trackSignUpAttempt('google');
  }, []);

  const handleGoogleSignUp = useCallback(async (data: CredentialResponse) => {
    if (data.credential) {
      const response = await fetcher(`${API_BASE_URL}/auth/google-signup`, {
        method: 'POST',
        body: JSON.stringify({
          accessToken: data.credential,
          utmSource: utmSource || undefined,
        }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        await validateAndTrackSignUp({ token: response.result, event: 'sign_up_google' });
        await trackSignUpSuccess('google');
        setToken(response.result, { isOnboarding: true });
      } else {
        await trackSignUpError('google', response?.messages?.[0]);
        if (response.messages && response.messages.length) {
          setErrorMessage(response.messages[0]);
        }
      }
    } else {
      trackSignUpError('google', 'An error occurred while authorizing with Google');
    }
  }, []);

  useInitializeGoogleAuth({
    callback: handleGoogleSignUp,
    clickListener: trackGoogleSignUp,
    shouldPrompt: true,
    elementId: 'google-button',
    buttonStyles: {
      theme: 'filled_black',
    },
  });

  return (
    <FormWrapper>
      <Image
        alt={Assets.images.signUpFormHeader.alt}
        height={340}
        src={Assets.images.signUpFormHeader.url}
        width={340}
      />
      <HeaderTitle>Get Started for FREE</HeaderTitle>
      <div style={{ width: '100%' }}>
        {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        <Formik
          initialValues={{ name: '', email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ handleChange, handleSubmit, handleBlur, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Input
                  label="First Name"
                  name="name"
                  placeholder="First Name"
                  style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                  type="text"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Input
                  label="Your Email"
                  name="email"
                  placeholder="Your Email"
                  style={{ margin: '1px 0', borderRadius: 0 }}
                  type="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Input
                  label="Password"
                  name="password"
                  placeholder="Password"
                  style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  type="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ButtonWrapper>
                  <BaseButton
                    analyticsEvent={{ category: 'Signup Form', action: 'Signup Button Click' }}
                    isFullWidth={true}
                    isLoading={isLoading}
                    testId="handle-sign-up"
                    type="submit"
                  >
                    CREATE ACCOUNT
                  </BaseButton>
                </ButtonWrapper>
              </Form>
            );
          }}
        </Formik>
        <OtherOptions type={'sign up'} />
        <SignUpWrapper>
          <AppleButton
            analyticsEvent={{ category: 'Signup Form', action: 'Sign in With Apple Button Click' }}
            onClick={() => handleAppleSignUp()}
          />
          <FacebookButton
            analyticsEvent={{
              category: 'Signup Form',
              action: 'Sign in With Facebook Button Click',
            }}
            onClick={() => FB.login(handleFacebookSignUp, { scope: 'public_profile,email' })}
          />
          <div id="google-button" style={{ height: '44px' }} />
        </SignUpWrapper>
      </div>
      <Disclaimer />
      <BottomContent>
        <div>Already have an account?</div>
        <Link href={WEB_APP_SIGNIN_URL}>
          <GhostButton
            analyticsEvent={{ category: 'Signup Form', action: 'Sign in Button Click' }}
            style={{
              padding: '0.5rem 1.2rem',
              fontSize: '12px',
              borderColor: 'rgba(255, 255, 255, .2)',
            }}
          >
            SIGN IN
          </GhostButton>
        </Link>
      </BottomContent>
    </FormWrapper>
  );
};

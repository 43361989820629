import styled from 'styled-components';

import Button, { ButtonProps } from './Button';

export const GhostButtonContainer = styled(Button)`
  border-radius: 4rem;
  padding: 0.8rem 2rem;
  border: 1px solid white;
`;

const StretchButton = styled(GhostButtonContainer)`
  flex-grow: 1;
`;

export const GhostButton: React.FC<ButtonProps> = ({
  children,
  style,
  analyticsEvent,
  onClick,
  isLoading = false,
}) => {
  return (
    <GhostButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      isLoading={isLoading}
      style={style}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </GhostButtonContainer>
  );
};

export const GhostButtonWide: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
}) => {
  return (
    <StretchButton
      data-analytics-event={JSON.stringify(analyticsEvent)}
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </StretchButton>
  );
};

// quick and easy because i had five minutes
// - dan p <3
const LoadingContainer = styled.div`
  animation: shimmer 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  @keyframes shimmer {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Loading() {
  return <LoadingContainer>Submitting...</LoadingContainer>;
}

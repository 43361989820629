import styled from 'styled-components';

import Button, { ButtonProps } from './Button';

export const PrimaryButtonContainer = styled(Button)`
  background: linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%);
  border-radius: 4rem;
  box-shadow: 0px 2.542509078979492px 11.441289901733398px 0px rgba(147, 35, 102, 0.4);
  padding: 0.8rem 2rem;
`;

const StretchButton = styled(PrimaryButtonContainer)`
  flex-grow: 1;
`;

export const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
  testId,
}) => {
  return (
    <PrimaryButtonContainer
      data-analytics-event={JSON.stringify(analyticsEvent)}
      data-testid={testId}
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </PrimaryButtonContainer>
  );
};

export const PrimaryButtonWide: React.FC<ButtonProps> = ({
  children,
  analyticsEvent,
  onClick,
  isLoading = false,
  testId,
}) => {
  return (
    <StretchButton
      data-analytics-event={JSON.stringify(analyticsEvent)}
      data-testid={testId}
      isLoading={isLoading}
      onClick={isLoading ? undefined : onClick}
    >
      {isLoading ? <Loading /> : children}
    </StretchButton>
  );
};

// quick and easy because i had five minutes
// - dan p <3
const LoadingContainer = styled.div`
  animation: shimmer 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  @keyframes shimmer {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

function Loading() {
  return <LoadingContainer>Submitting...</LoadingContainer>;
}

import { Router, useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK, WEB_APP_SIGNIN_URL } from '../../globals';
import { useSignUpHandler } from '../../hooks/useSignUpHandler';
import { PromoBanner } from '../../pages/api/promoBanner';
import { trackDownloadClick } from '../../utils/analytics';
import { DeviceTypes } from '../../utils/getDeviceType';
import {
  HeaderDisplay,
  HeaderSignInDownloadsDisplay,
  HeaderSignInSignUpActionsDisplay,
} from './Header.display';
import { PartnerType } from './PartnerBanner';

export function HeaderContainer(props: {
  deviceType: DeviceTypes;
  promoBanner: PromoBanner | null;
}): JSX.Element {
  const onSignUp = useSignUpHandler();

  const [partner, setPartner] = useState<PartnerType | null>(null);

  // had to type cast because NextRouter doesn't have "components" property
  const router = useRouter() as Router;

  useEffect(() => {
    if (router.pathname === '/[partner]') {
      const partnerComponent = router.components[router.pathname];
      setPartner(partnerComponent.props?.pageProps?.partner);
    } else {
      setPartner(null);
    }
  }, [router.components, router.pathname]);

  function handleClickSignIn() {
    window.location.href = WEB_APP_SIGNIN_URL;
  }

  function handleClickDownload() {
    trackDownloadClick();

    if (props.deviceType === DeviceTypes.Android) {
      window.location.href = ANDROID_DOWNLOAD_LINK;
    } else if (props.deviceType === DeviceTypes.IOS) {
      window.location.href = IOS_DOWNLOAD_LINK;
    }
  }

  const shouldHideHeaderActions = Boolean(partner);
  const headerActions =
    props.deviceType === DeviceTypes.Desktop ? (
      <HeaderSignInSignUpActionsDisplay
        onClickSignIn={handleClickSignIn}
        onClickSignUp={onSignUp}
      />
    ) : (
      <HeaderSignInDownloadsDisplay onClickDownload={handleClickDownload} />
    );

  return (
    <HeaderDisplay
      headerActions={shouldHideHeaderActions ? null : headerActions}
      isDownloadPage={router.pathname === '/download'}
      partner={partner}
      promoBanner={props.promoBanner}
    />
  );
}

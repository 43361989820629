import dynamic from 'next/dynamic';
import React, { useCallback } from 'react';

import { PromoBanner } from '../../pages/api/promoBanner';
import { BrandLogo } from '../_core/BrandLogo';
import MaxWidth from '../shared/MaxWidth';
import * as S from './Header.styles';
import { HeaderActionButton } from './HeaderActionButton';
import { PartnerBanner, PartnerType } from './PartnerBanner';

type Props = {
  headerActions: JSX.Element;
  partner?: PartnerType;
  promoBanner: PromoBanner | null;
  isDownloadPage: boolean;
};

const Navigation = dynamic(() => import('./Navigation'), {
  ssr: true,
});

export function HeaderDisplay(props: Props): JSX.Element {
  const onClickPromoBanner = useCallback(() => {
    if (!props.promoBanner?.link) return;
    window.location.assign(props.promoBanner.link);
  }, [props.promoBanner?.link]);

  return (
    <S.Container>
      {props.partner ? <PartnerBanner partner={props.partner} /> : null}
      {!props.partner && props.promoBanner ? (
        <PartnerBanner
          partner={{
            bannerText: props.promoBanner.text,
            bannerStyle: props.promoBanner.bannerStyle,
          }}
          onClick={onClickPromoBanner}
        />
      ) : null}

      <S.HeaderDiv
        style={{
          background: props.isDownloadPage
            ? 'linear-gradient(180deg, #000000 0%, rgba(25, 23, 54, 0) 80%)'
            : 'transparent',
        }}
      >
        <MaxWidth style={{ maxWidth: props.isDownloadPage ? '1220px' : '1328px' }}>
          <S.HeaderWrapper>
            <S.LeftContainer>
              <BrandLogo analytics={{ category: 'Header', action: 'Brand Header Click' }} />
              <Navigation />
            </S.LeftContainer>
            <S.RightContainer>{props.headerActions}</S.RightContainer>
          </S.HeaderWrapper>
        </MaxWidth>
      </S.HeaderDiv>
    </S.Container>
  );
}

export function HeaderSignInSignUpActionsDisplay(props: {
  onClickSignIn: () => void;
  onClickSignUp: () => void;
}): JSX.Element {
  return (
    <>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Sign In Button Click' }}
        hasBorder={false}
        testId="sign-in"
        onClick={props.onClickSignIn}
      >
        SIGN IN
      </HeaderActionButton>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Signup Button Click' }}
        hasBorder
        testId="sign-up"
        onClick={props.onClickSignUp}
      >
        TRY BRAIN.FM FOR FREE
      </HeaderActionButton>
    </>
  );
}

export function HeaderSignInDownloadsDisplay(props: { onClickDownload: () => void }): JSX.Element {
  return (
    <>
      <HeaderActionButton
        analyticsEvent={{ category: 'Header', action: 'Download Button Click' }}
        onClick={props.onClickDownload}
      >
        DOWNLOAD
      </HeaderActionButton>
    </>
  );
}

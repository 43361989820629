import styled from 'styled-components';

import Button, { ButtonProps } from './Button';

export const SecondaryButtonContainer = styled(Button)`
  padding: 0.9rem 2.25rem;
`;

export const SecondaryButton: React.FC<ButtonProps> = ({ children, analyticsEvent, onClick }) => {
  return (
    <SecondaryButtonContainer data-analytics-event={JSON.stringify(analyticsEvent)} onClick={onClick}>
      {children}
    </SecondaryButtonContainer>
  );
};
